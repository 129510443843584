import React, { createContext, useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  User,
  signOut,
} from "firebase/auth";
import { doc, getFirestore } from "firebase/firestore/lite";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Main from "./main";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { myTheme } from "./theme";
import { ErrorPage } from "./components";
import { projectList, ProjectPage } from "./projects";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCSbUyFGc9aewxMZfl5B1VSkDj19LNh-7U",
  authDomain: "tvbproject-8424d.firebaseapp.com",
  projectId: "tvbproject-8424d",
  storageBucket: "tvbproject-8424d.appspot.com",
  messagingSenderId: "326562826376",
  appId: "1:326562826376:web:4a3c168f757381cb2eb73d",
  measurementId: "G-Y22FY5WSHG",
};
const firebaseApp = initializeApp(firebaseConfig);

// Firestore
const db = getFirestore(firebaseApp);
export function getMyDoc(collectionName: string) {
  return auth.currentUser
    ? doc(db, collectionName + "/" + auth.currentUser.uid)
    : null;
}

// Authentication
const provider = new GoogleAuthProvider();
provider.setCustomParameters({
  prompt: "select_account ",
});
const auth = getAuth();
export const signInWithGooglePopup = () => signInWithPopup(auth, provider);
export const signOutUser = () => signOut(auth);

export const UserContext = createContext<User | null>(null);
const UserProvider = (props: any) => {
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      setUser(user);
    });
  }, []);

  return (
    <UserContext.Provider value={user}>{props.children}</UserContext.Provider>
  );
};
// const analytics = getAnalytics(app);

// router
const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <ProjectPage />,
      },
      ...Object.values(projectList).map((project) => ({
        path: project.path,
        element: <project.component />,
      })),
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={myTheme}>
      <CssBaseline />
      <UserProvider>
        <RouterProvider router={router} />
      </UserProvider>
    </ThemeProvider>
  </React.StrictMode>,
);
