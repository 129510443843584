import { Stack, Typography } from "@mui/material";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "..";
import { Button } from "../components";

export default function ProjectPage() {
  const user = useContext(UserContext);

  return (
    <Stack spacing={1}>
      <Typography variant="h2" fontWeight="bold">
        TVB's Website
      </Typography>
      <div>{"Hello world" + (user ? " and " + user?.displayName : "")}</div>
      <Stack direction="row" spacing={1}>
        <Link to="/notepad">
          <Button variant="contained">Go to notepad</Button>
        </Link>
      </Stack>
    </Stack>
  );
}
