import Notepad from "./notepad/notepad";

export { default as ProjectPage } from "./project-page";

export const projectList: Record<
  string,
  { name: string; path: string; component: () => any }
> = {
  notepad: {
    name: "Notepad",
    path: "notepad",
    component: Notepad,
  },
};
