import { useState } from "react";
import { Outlet } from "react-router-dom";
import { Box, Stack, styled, useTheme } from "@mui/material";
import { ImageBox, Info, Navbar } from "./components";
import { Komodo, LA } from "./images";

const StyledInnerBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderWidth: "4px",
  borderColor: theme.palette.primary.main,
  borderStyle: "solid",
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
  elevation: 24,
}));

export default function Main() {
  const [isWidescreen] = useState<boolean>(false);
  const isDark = useTheme().palette.mode === "dark";

  return (
    <Box
      style={{
        minWidth: "350px",
        backgroundColor: useTheme().palette.background.default,
        height: "100vh",
      }}
    >
      <ImageBox src={isDark ? LA : Komodo} height="100vh">
        <Stack
          maxWidth={isWidescreen ? "xl" : "lg"}
          spacing={1}
          padding={1}
          direction={
            isWidescreen
              ? "column-reverse"
              : { xs: "column-reverse", md: "row" }
          }
        >
          <StyledInnerBox>
            <Info />
          </StyledInnerBox>
          <Stack spacing={1} width="100%">
            <StyledInnerBox>
              <Navbar />
            </StyledInnerBox>
            <StyledInnerBox>
              <Outlet />
            </StyledInnerBox>
          </Stack>
        </Stack>
      </ImageBox>
    </Box>
  );
}
