import { Stack, TextField, Typography } from "@mui/material";
import { getMyDoc, signInWithGooglePopup, UserContext } from "../../index";
import { getDoc, setDoc } from "firebase/firestore/lite";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  CheckCircleOutlineRounded,
  HistoryRounded,
  SaveRounded,
} from "@mui/icons-material";
import { Button } from "../../components";

export default function Notepad() {
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [isWriting, setIsWriting] = useState<boolean>(false);
  const [myText, setMyText] = useState<string>("");
  const [loadedText, setLoadedText] = useState<string>("");

  const user = useContext(UserContext);

  // Load data from Firestore into mySavedText
  async function loadData() {
    const myDoc = getMyDoc("/notepad");
    const newText = myDoc ? (await getDoc(myDoc)).data()?.text : "";
    setMyText((prev) =>
      Boolean(prev && newText)
        ? prev.localeCompare(newText) === 0
          ? prev
          : newText + ", " + prev
        : Boolean(newText)
          ? newText
          : prev,
    );
    setLoadedText(newText);
  }

  // Write data to Firestore from myNewText
  async function writeData() {
    setIsWriting(true);
    const myDoc = getMyDoc("/notepad");
    if (myDoc) {
      await setDoc(myDoc, { text: myText });
      setLoadedText(myText);
    } else {
      setMyText(loadedText);
    }
    setIsWriting(false);
  }

  // Revert data to previous state
  async function revertData() {
    setIsWriting(true);
    setMyText(loadedText);
    setIsWriting(false);
  }

  // Gets new text when user changes
  useEffect(() => {
    setIsFetching(true);
    loadData();
    setIsFetching(false);
  }, [user]);

  const isLoading = useMemo(
    () => isFetching || isWriting,
    [isFetching, isWriting],
  );
  const madeChanges = useMemo(
    () => loadedText.localeCompare(myText),
    [loadedText, myText],
  );

  return (
    <Stack spacing={1}>
      <Stack>
        <Typography variant="h4" fontWeight="bold">
          Notepad
        </Typography>

        <Typography variant="subtitle2">
          Notes you write will be saved for later
        </Typography>
      </Stack>

      <TextField
        disabled={isLoading}
        name="notepad"
        multiline
        value={isFetching ? "Loading..." : myText}
        onChange={(e) => setMyText(e.target.value)}
      ></TextField>

      <Stack direction="row" spacing={1}>
        {!user ? (
          <Button
            variant="contained"
            startIcon={<SaveRounded />}
            onClick={signInWithGooglePopup}
          >
            Save your notes
          </Button>
        ) : !isLoading && madeChanges ? (
          <>
            <Button
              startIcon={<HistoryRounded />}
              onClick={revertData}
              variant="outlined"
            >
              Revert
            </Button>

            <Button
              startIcon={<SaveRounded />}
              onClick={writeData}
              variant="contained"
            >
              Save
            </Button>
          </>
        ) : (
          <Button
            startIcon={isLoading ? null : <CheckCircleOutlineRounded />}
            style={{ backgroundColor: "transparent" }}
            disableRipple
            variant="text"
          >
            {isLoading ? "Loading..." : "Up to date"}
          </Button>
        )}
      </Stack>
    </Stack>
  );
}
