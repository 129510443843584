import { createTheme } from "@mui/material";

const myGreen = "#117711";
const myBlueLight = "#88CCEE";
const myBlueDark = "#221188";

export const myTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 350,
      md: 700,
      lg: 1050,
      xl: 1400,
    },
  },
  colorSchemes: {
    light: {
      palette: {
        info: {
          main: myBlueLight,
        },
        primary: {
          main: myGreen,
        },
        secondary: {
          main: myBlueLight,
        },
        success: {
          main: myGreen,
        },
      },
    },
    dark: {
      palette: {
        info: {
          main: myBlueDark,
        },
        primary: {
          main: myGreen,
        },
        secondary: {
          main: myBlueDark,
        },
        success: {
          main: myGreen,
        },
      },
    },
  },
  shape: {
    borderRadius: 8,
  },
  spacing: 8,
});
