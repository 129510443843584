import {
  AccountBoxRounded,
  CasinoRounded,
  Google,
  HomeRounded,
  LogoutRounded,
  SearchRounded,
} from "@mui/icons-material";
import {
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  TextField,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button } from ".";
import { projectList } from "../projects";
import { useContext, useState } from "react";
import { signInWithGooglePopup, signOutUser, UserContext } from "..";

export default function Navbar() {
  const currentPage = useLocation()?.pathname.substring(1);
  const user = useContext(UserContext);

  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const navigate = useNavigate();

  return (
    <Stack
      direction="row"
      overflow="hidden"
      alignItems="center"
      justifyContent="space-between"
      spacing={1}
    >
      <Stack
        direction="row"
        overflow="hidden"
        alignItems="center"
        spacing={1}
        width="100%"
      >
        <Link
          to={
            "/" +
            (currentPage
              ? ""
              : Object.keys(projectList)[
                  Math.floor(Math.random() * Object.keys(projectList).length)
                ])
          }
        >
          <Button
            startIcon={currentPage ? <HomeRounded /> : <CasinoRounded />}
            variant="contained"
          >
            {currentPage ? "Home" : "Random"}
          </Button>
        </Link>

        <TextField
          fullWidth
          size="small"
          name="project_list"
          select
          value={
            Object.keys(projectList).includes(currentPage) ? currentPage : ""
          }
          style={{
            maxWidth: "300px",
          }}
          sx={{
            "& .MuiInputBase-input": {
              overflow: "hidden",
              textOverflow: "ellipsis",
            },
          }}
          onChange={(event) => navigate(event.target.value)}
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">
                  <SearchRounded />
                </InputAdornment>
              ),
            },
          }}
        >
          {Object.values(projectList).map((project) => (
            <MenuItem key={project.path} value={project.path}>
              {project.name}
            </MenuItem>
          ))}
        </TextField>
      </Stack>

      <Button
        variant="contained"
        endIcon={<AccountBoxRounded />}
        onClick={(event) => setMenuAnchor(event.currentTarget)}
      >
        {user?.displayName?.match(/\b[a-zA-Z]/g)?.join("") ?? "Sign in"}
      </Button>

      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuList dense>
          <MenuItem
            onClick={() => {
              user ? signOutUser() : signInWithGooglePopup();
              handleMenuClose();
            }}
          >
            <ListItemIcon>{user ? <LogoutRounded /> : <Google />}</ListItemIcon>
            <ListItemText>
              {user ? "Sign out" : "Sign in with Google"}
            </ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>
    </Stack>
  );
}
