import { ButtonProps, Button as MuiButton, styled } from "@mui/material";

export const Button = styled(({ size = "small", ...props }: ButtonProps) => (
  <MuiButton size={size} {...props} />
))({
  textTransform: "none",
  fontWeight: "bold",
  flex: "none",
  whiteSpace: "nowrap",
});
